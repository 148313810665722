import { authProvider } from 'AuthProvider';
import SessionHelper from 'services/session';
import axios from 'axios';
import { logout } from 'actions/session/sessionActions';

axios.defaults.withCredentials = true;
axios.defaults.xsrfCookieName = 'XSRF-TOKEN'; // Response
axios.defaults.xsrfHeaderName = 'X-XSRF-TOKEN'; // Request
axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;

const { headers } = axios.defaults;
headers.common['Content-Type'] = 'application/json';
headers.common.Accept = 'application/json,application/octet-stream';

const ERROR_MESSAGE =
  "com.nimbusds.jose.RemoteKeySourceException: Couldn't retrieve remote JWK set: Read timed out";

axios.interceptors.request.use(
  async (config) =>
    // Check and acquire a token before the request is sent
    new Promise((resolve, reject) => {
      const account = authProvider.getAllAccounts()[0];
      if (account) {
        // const token = await authProvider.getAccessToken();
        // config.headers.Authorization = `Bearer ${token.accessToken}`;
        resolve(config);
        SessionHelper.resetExpiryTimeout(); // reset/prolong session on every API request
      } else {
        // logout();
        // Do something with error of acquiring the token
        reject(config);
      }
      // });
    }),
  (error) =>
    // Do something with error of the request
    Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => Promise.resolve(response),
  async (error) => {
    const originalRequest = error.config;
    if (
      error &&
      error.response &&
      error.response &&
      error.response.data &&
      !originalRequest._retry
    ) {
      if (error.response.data.message === ERROR_MESSAGE) {
        try {
          originalRequest._retry = true;
          const result = await axios(originalRequest);
          return result;
        } catch (e) {
          return Promise.reject(error);
        }
      }
    }
    return Promise.reject(error);
  }
);

export { axios };

export const handleSessionExpiry = () => {
  const account = authProvider.getAllAccounts()[0];
  // session management initialization
  if (account) {
    // Set the expiry time out from now
    SessionHelper.setExpiry();
    // At the initialisation of our app we start a session timeout function, which will be triggered
    // after the amount of minutes set in our session config.
    // But first we'll provide a callback to execute at the timeout.
    SessionHelper.expiryTimeoutCallback = () => {
      // we will check the token expiry time and log out the user if necessary.
      if (SessionHelper.isTokenExpiredOrNull) {
        logout();
      } else {
        SessionHelper.resetExpiryTimeout(); // try again later
      }
    };
    // Then we'll start the timer
    SessionHelper.startExpiryTimeout();
    // After we've prepared everything for the session helper we render the authenticated part of our app
  }
};
